.footer {
    position:fixed;
    bottom:0;
    height:60px;
}
/* 
.left {
    float: left;
    text-align: left;
 }
  */