@media (min-width:700px) {
    .ion-page {
        width: 700px !important;
        margin: auto;
    }
  
    .scroll-content {
      overflow-y:auto;
    }

}